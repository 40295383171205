/*======================== 
    Common Style Css
==========================*/
section.abhtbnr {
    padding-bottom: 80px;
    padding-top: 80px;
}
section.thankyou {
  padding-top: 180px;
  padding-bottom: 130px;
  background: #edeeef;
  margin: 0 auto;
  display: block;
  text-align: center;
}
section.ser {
    background: linear-gradient(90deg,#080c1c,#040e3a);
    padding: 80px 0;
}
.fact-counter-area {
    border-top: 3px solid #de000a;
    padding: 39px 45px 11px;
}
img.footerlogo {
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 30px;
}
.add a {
    color: #fff;
}
.add {
    margin: 0 auto;
    display: block;
    text-align: center;
    padding-top: 18px;
}
.add p {
    color: #fff;
    margin-bottom: 5px;
}
.solution_area .solution-item h4 {
      font-size: 18px;
    font-weight: 600;
    margin-top: 18px;
}
.feature-register form#myForm textarea#message {
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-top: 10px;
    width: 100%;
}
.single-fact-wrap {
    margin-bottom: 30px;
    padding-left: 15px;
    position: relative;
}
.single-fact-wrap h5 {
    color: #fff;
    line-height: 31px;
    margin-bottom: 10px;
}
.feature-register {
    background: #fff;
    border: 1px solid #ededed;
    box-shadow: 10px 6px 40px 0 rgba(26,32,44,.06), 8px 4px 40px 0 rgba(26,32,44,.06);
    margin: 0 auto;
    padding: 30px;
    transition: box-shadow .3s ease;
    width: 450px;
}
.single-fact-wrap p {
    color: #d6d6d6;
    line-height: 26px;
    margin-bottom: 0;
}
section.bandwidth {
    padding-bottom: 100px;
    padding-top: 100px;
}
.feature-register form#myForm input {
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    line-height: 42px;
    margin-bottom: 16px;
    padding-left: 15px;
    width: 100%;
}
.solution_area {
    background-color: #fff;
    background-color: var(--secondary-color);
    padding: 100px 0 70px;
}
.single-fact-wrap:after {
    background: #7c7979;
    content: "";
    height: 140px;
    position: absolute;
    right: -15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
}
.single-fact-wrap {
    margin-bottom: 30px;
    padding-left: 15px;
    position: relative;
}
.single-fact-wrap h2 {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
    line-height: 57px;
    margin-bottom: 8px;
}
.justify-content-center {
    justify-content: center!important;
}
section.ser {
    background: linear-gradient(90deg,#080c1c,#040e3a);
    padding: 80px 0;
}
section.ser h2 {
    color: #fff;
    font-family: Mulish,sans-serif;
    font-size: 43px;
    font-weight: 900;
    margin-bottom: 30px;
    text-transform: capitalize;
}
p.Praesent {
    color: #fff;
    margin-bottom: 20px;
}
div#serrow {
    margin-bottom: 25px;
}
.servicebox {
    background: #fff;
    border: 1px solid #d1cfcf;
    border-radius: 5px;
    height: 300px;
    padding: 30px;
}
section.benefit {
    padding: 20px 0 80px;
}
.staffbenfits h3 {
    font-size: 25px;
}
.staffbenfits h3 {
    font-size: 25px;
}
img.staff {
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
}
hr {
    border: 0;
    border-top: 1px solid !important;
    color: inherit;
    margin: 1rem 0 !important;
    opacity: .25;
}
/* Theme Gradient */
.theme-gradient {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100%;
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-theme-gradient {
    background-color: transparent;
    background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary-alt) 90%);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .theme-gradient {
        color: var(--color-secondary);
        background: none !important;
    }
}

/* Heading Font  */
.h1 {
    font-size: var(--h1) !important
}

.h2 {
    font-size: var(--h2) !important
}

.h3 {
    font-size: var(--h3) !important
}

.h4 {
    font-size: var(--h4) !important
}

.h5 {
    font-size: var(--h5) !important
}

.h6 {
    font-size: var(--h6) !important
}



/* Text Color  */

.color-primary {
    @extend %color-primary;
}

.color-primary {
    @extend %color-primary;
}

.color-primary-light {
    @extend %color-primary-light;
}

.color-primary-alt {
    @extend %color-primary-alt;
}

.color-secondary {
    @extend %color-secondary;
}

.color-tertiary {
    @extend %color-tertiary;
}

.color-tertiary-alt {
    @extend %color-tertiary-alt;
}

.color-light-green {
    @extend %color-light-green;
}

.color-pink {
    @extend %color-pink;
}

.color-primary-darker {
    @extend %color-primary-darker;
}

.color-secondary-darker {
    @extend %color-secondary-darker;
}

.color-light-green-darker {
    @extend %color-light-green-darker;
}

.color-pink-darker {
    @extend %color-pink-darker;
}

.color-heading {
    @extend %color-heading;
}

.color-body {
    @extend %color-body;
}

.color-dark {
    @extend %color-dark;
}

.color-darker {
    @extend %color-darker;
}

.color-darkest {
    @extend %color-darkest;
}

.color-black {
    @extend %color-black;
}

.color-blacker {
    @extend %color-blacker;
}

.color-blackest {
    @extend %color-blackest;
}

.color-border {
    @extend %color-border;
}

.color-gray {
    @extend %color-gray;
}

.color-midgray {
    @extend %color-midgray;
}

.color-light {
    @extend %color-light;
}

.color-lighter {
    @extend %color-lighter;
}

.color-lightest {
    @extend %color-lightest;
}

.color-white {
    @extend %color-white;
}




//===== BG Ncc Colors =====//

.bg-primary-color {
    background: var(--color-primary);
}

.bg-secondary-color {
    background: var(--color-secondary)
}

.bg-tertiary-color {
    background-color: var(--color-tertiary)
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary-light {
    background-color: var(--color-primary-light) !important;
}

.bg-color-primary-alt {
    background-color: var(--color-primary-alt) !important;
}

.bg-color-secondary {
    background-color: var(--color-secondary) !important;
}

.bg-color-tertiary {
    background-color: var(--color-tertiary) !important;
}

.bg-color-tertiary-alt {
    background-color: var(--color-tertiary-alt) !important;
}

.bg-color-light-green {
    background-color: var(--color-light-green) !important;
}

.bg-color-pink {
    background-color: var(--color-pink) !important;
}

.bg-color-primary-darker {
    background-color: var(--color-primary-darker) !important;
}

.bg-color-secondary-darker {
    background-color: var(--color-secondary-darker) !important;
}

.bg-color-light-green-darker {
    background-color: var(--color-light-green-darker) !important;
}

.bg-color-pink-darker {
    background-color: var(--color-pink-darker) !important;
}

.bg-color-heading {
    background-color: var(--color-heading) !important;
}

.bg-color-body {
    background-color: var(--color-body) !important;
}

.bg-color-dark {
    background-color: var(--color-dark) !important;
}

.bg-color-darker {
    background-color: var(--color-darker) !important;
}

.bg-color-darkest {
    background-color: var(--color-darkest) !important;
}

.bg-color-black {
    background-color: var(--color-black) !important;
}

.bg-color-blacker {
    background-color: var(--color-blacker) !important;
}

.bg-color-blackest {
    background-color: var(--color-blackest) !important;
}

.bg-color-border {
    background-color: var(--color-border) !important;
}

.bg-color-gray {
    background-color: var(--color-gray) !important;
}

.bg-color-midgray {
    background-color: var(--color-midgray) !important;
}

.bg-color-light {
    background-color: var(--color-light) !important;
}

.bg-color-lighter {
    background-color: var(--color-lighter) !important;
}

.bg-color-lightest {
    background-color: var(--color-lightest) !important;
}

.bg-color-white {
    background-color: var(--color-white) !important;
}




/* Radius */
.radius-small {
    @extend %radius-small;
}

.radius {
    @extend %radius;
}

.radius-big {
    @extend %radius-big;
}


/* Font Weight */

.w-300 {
    @extend %w-300;
}

.w-400 {
    @extend %w-400;
}

.w-500 {
    @extend %w-500;
}

.w-600 {
    @extend %w-600;
}

.w-700 {
    @extend %w-700;
}

.w-800 {
    @extend %w-800;
}

.w-900 {
    @extend %w-900;
}



/* Shadows */

.shadow-primary {
    @extend %shadow-primary;
}

.shadow-light {
    @extend %shadow-light;
}

.shadow-lighter {
    @extend %shadow-lighter;
}



/* Others  */
.liststyle {
    padding: 0;
    margin: 0;
    list-style: none;
}

.radius {
    @extend %radius;
}

%transition-transform {
    transition: var(--transition-transform);
}

.transition-transform {
    @extend %transition-transform;
}


.list-icon {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        align-items: center;
        margin: 15px 0;
        @media #{$sm-layout} {
            font-size: 16px;
        }
        .icon {
            width: 30px;
            background-color: var(--color-blackest);
            height: 30px;
            border-radius: 100%;
            display: inline-block;
            position: relative;
            margin-right: 9px;
            min-width: 30px;
            min-height: 30px;
            svg {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.rn-sub-badge {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    display: inline-block;
    padding: 7px 14px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

@media (max-width: 480px) and (min-width: 320px){
    .banner-area-1 .banner-inner {
      padding: 130px 0 37px !important;
  }
  section.thankyou {
    padding-top: 130px;
    padding-bottom: 50px;
    background: #edeeef;
    margin: 0 auto;
    display: block;
    text-align: center;
}
  .single-fact-wrap:after{
    display: none;
      }
  section#Services {
    padding: 60px 20px;
}
  .button-group.mt--30 {
    margin-top: 0px !important;
}
  .slider-style-4 .inner .description {
    font-size: 18px !important;
    line-height: 28px !important;
    color: #fff !important;
}
  .slider-style-4 .inner .title {
    font-size: 32px !important;
    line-height: 42px !important;
  }
  section.bandwidth {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .feature-register {
    width: 100%;
  }
  .fact-counter-area {
    margin-bottom: 0;
    transform: none;
    margin-top: 0px !important;
  }
  .solution_area {
    background-color: var(--secondary-color);
    padding: 60px 0 70px;
  }
  div#serrow {
    margin-bottom: 0px;
  }
  .servicebox h4 {
    font-size: 21px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .servicebox {
    padding: 30px;
    background: #fff;
    border: 1px solid #d1cfcf;
    border-radius: 5px;
    height: unset;
    margin-bottom: 10px;
  }
  section.ser {
    padding: 60px 0px;}
  section.ser h2 {
    font-size: 27px;
    line-height: 37px;}
  .staffbenfits h3 {
    font-size: 22px;
    margin-top: 6px;
    margin-bottom: 0px;
  }
  section.benefit {
    padding: 20px 0px 60px 0px;
  }
  section.abhtbnr h2 {
    margin-top: 30px;
    font-size: 27px;
    line-height: 34px;}
  section.abhtbnr {
    padding-top: 27px;
    padding-bottom: 30px;
  }
  }
  @media (max-width: 580px) and (min-width: 481px){
    .banner-area-1 .banner-inner {
      padding: 130px 0 37px !important;
  }
  section.thankyou {
    padding-top: 130px;
    padding-bottom: 50px;
    background: #edeeef;
    margin: 0 auto;
    display: block;
    text-align: center;
}
  section#Services {
    padding: 60px 20px;
}
  .button-group.mt--30 {
    margin-top: 0px !important;
}
  .slider-style-4 .inner .description {
    font-size: 18px !important;
    line-height: 28px !important;
    color: #fff !important;
}
  .slider-style-4 .inner .title {
    font-size: 32px !important;
    line-height: 42px !important;
  }
  section.bandwidth {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .feature-register {
    width: 100%;
  }
  .fact-counter-area {
    margin-bottom: 0;
    transform: none;
    margin-top: 0px !important;
  }
  .solution_area {
    background-color: var(--secondary-color);
    padding: 60px 0 70px;
  }
  div#serrow {
    margin-bottom: 0px;
  }
  .servicebox h4 {
    font-size: 21px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .servicebox {
    padding: 30px;
    background: #fff;
    border: 1px solid #d1cfcf;
    border-radius: 5px;
    height: unset;
    margin-bottom: 10px;
  }
  section.ser {
    padding: 60px 20px;}
  section.ser h2 {
    font-size: 27px;
    line-height: 37px;}
  .staffbenfits h3 {
    font-size: 22px;
    margin-top: 6px;
    margin-bottom: 0px;
  }
  section.benefit {
    padding: 20px 0px 60px 0px;
  }
  section.abhtbnr h2 {
    margin-top: 30px;
    font-size: 27px;
    line-height: 34px;}
  section.abhtbnr {
    padding-top: 27px;
    padding-bottom: 30px;
  }
  }
  @media (max-width: 767px) and (min-width: 581px){
    .banner-area-1 .banner-inner {
      padding: 130px 0 37px !important;
  }
  section.thankyou {
    padding-top: 130px;
    padding-bottom: 50px;
    background: #edeeef;
    margin: 0 auto;
    display: block;
    text-align: center;
}
  .single-fact-wrap:after{
    display: none;
      }
  section#Services {
    padding: 60px 20px;
}
  section.bandwidth {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .feature-register {
    width: 100%;
  }
  .fact-counter-area {
    margin-bottom: 0;
    transform: none;
    margin-top: 0px !important;
  }
  .solution_area {
    background-color: var(--secondary-color);
    padding: 60px 0 70px;
  }
  div#serrow {
    margin-bottom: 0px;
  }
  .servicebox h4 {
    font-size: 21px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .servicebox {
    padding: 30px;
    background: #fff;
    border: 1px solid #d1cfcf;
    border-radius: 5px;
    height: unset;
    margin-bottom: 10px;
  }
  section.ser {
    padding: 60px 20px;}
  section.ser h2 {
    font-size: 27px;
    line-height: 37px;}
  .staffbenfits h3 {
    font-size: 22px;
    margin-top: 6px;
    margin-bottom: 0px;
  }
  section.benefit {
    padding: 20px 0px 60px 0px;
  }
  section.abhtbnr h2 {
    margin-top: 30px;
    font-size: 27px;
    line-height: 34px;}
  section.abhtbnr {
    padding-top: 27px;
    padding-bottom: 30px;
  }
  }
  @media (max-width: 992px) and (min-width: 768px){
    .banner-area-1 .banner-inner {
      padding: 150px 0 80px !important;
  }
  .height-850 {
    height: auto;
    padding: 86px 0 !important;
}
  .slider-style-4 .inner .description {
    font-size: 18px !important;
    color: #fff !important;
    line-height: 28px !important;}
  .slider-style-4 .inner .title {
    font-size: 42px !important;
    line-height: 52px !important;
    margin-bottom: 10px !important;
}
  .single-fact-wrap:after{
display: none;
  }
  .relationships {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .feature-register {
    width: 100%;
    margin-bottom: 60px;
  }
  section.bandwidth {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .solution_area {
    background-color: var(--secondary-color);
    padding: 60px 0 0px !important;
  }
  .fact-counter-area {
    margin-bottom: 0;
    transform: none;
    margin-top: 60px !important;
  }
  .solution-item {
    margin-bottom: 20px;
  }
  .servicebox {
    height: 285px;
    margin-bottom: 10px;
  }
  div#serrow {
    margin-bottom: 0px;
  }
  section#Services p br {
    display: none;
  }
  section.ser h2 {
    font-size: 33px;
     margin-bottom: 20px;}
  section.ser {
    padding: 60px 20px;
    background: linear-gradient(90deg, #080c1c 0%, #040e3a 100%);
  }
  img.staff {
    margin-top: 1px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .staffbenfits h3 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  section.abhtbnr h2 {
    font-size: 34px;
    line-height: 40px;
    text-transform: capitalize;
    margin-bottom: 20px;}
  }
  @media (max-width: 1024px) and (min-width: 993px){
    .banner-area-1 .banner-inner {
      padding: 160px 0 80px !important;
      position: relative;
  }
  section.thankyou {
    padding-top: 130px;
    padding-bottom: 50px;
    background: #edeeef;
    margin: 0 auto;
    display: block;
    text-align: center;
}
  .slider-style-4 .inner .title {
    font-size: 55px !important;
    line-height: 65px !important;
    margin-bottom: 22px !important;
}
  .height-850 {
    height: 632px !important;}

  .slider-style-4 .inner .description {
    font-size: 18px !important;
    color: #fff !important;
    line-height: 28px !important;}
  .slider-style-4 .inner .title {
    font-size: 42px !important;
    line-height: 52px !important;
    margin-bottom: 10px !important;
}
  section.bandwidth {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .feature-register {
    width: 100%;}
  section.ser {
    padding: 70px 0px 50px;
    background: linear-gradient(90deg, #080c1c 0%, #040e3a 100%);
  }
  div#serrow {
    margin-bottom: 0px;
  }
  .servicebox {
    height: 230px;
    margin-bottom: 20px;
  }
  section.ser h2 {
    font-size: 38px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 20px;}
  section.abhtbnr h2 {
    font-size: 38px;}
    .staffbenfits h3 {
      font-size: 22px;
      margin-top: 6px;
      margin-bottom: 0px;
  }
  }
  @media (max-width: 1199px) and (min-width: 1025px){
    .banner-area-1 .banner-inner {
      padding: 160px 0 80px !important;
      position: relative;
  }
  
  section.bandwidth {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .feature-register {
    width: 100%;}
  section.ser {
    padding: 70px 0px 50px;
    background: linear-gradient(90deg, #080c1c 0%, #040e3a 100%);
  }
  div#serrow {
    margin-bottom: 0px;
  }
  .servicebox {
    height: 230px;
    margin-bottom: 20px;
  }
  section.ser h2 {
    font-size: 38px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 20px;}
  section.abhtbnr h2 {
    font-size: 38px;}
    .staffbenfits h3 {
      font-size: 22px;
      margin-top: 6px;
      margin-bottom: 0px;
  }
  }
  @media (max-width: 1240px) and (min-width: 1200px){
    .banner-area .banner-inner.style-white .title {
      color: #fff;
      font-size: 60px !important;
      line-height: 70px !important;
  }
  .banner-area-1 .banner-inner {
    padding: 200px 0 110px !important;
    position: relative;
  }
  section.abhtbnr h2 {
    font-size: 40px;
    line-height: 50px;}
  }
  @media (max-width: 1399px) and (min-width: 1241px){
    .banner-area .banner-inner.style-white .title {
      color: #fff;
      font-size: 60px !important;
      line-height: 70px !important;
  }
  .banner-area-1 .banner-inner {
    padding: 200px 0 110px !important;
    position: relative;
  }
  section.abhtbnr h2 {
    font-size: 40px;
    line-height: 50px;}
  }
  @media (max-width: 1440px) and (min-width: 1400px){
    .banner-area .banner-inner.style-white .title {
      color: #fff;
      font-size: 60px !important;
      line-height: 70px !important;
  }
  .banner-area-1 .banner-inner {
    padding: 230px 0 120px !important;
    position: relative;
  }
  section.abhtbnr h2 {
    font-size: 40px;
    line-height: 50px;}
  }

